.bvm{
    .recent-post-img {
        height: 250px;
        width: 500px;
    }

    .recent-post-figure-img {
        min-height: 30px;
        min-width: 30px;
    }

    .title-wrap {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .content-wrap{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .expandSearch{
        display: inline-block;
        position: relative;
    }
    .expandSearch i{
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px 8px 8px 8px ;
        color: #777;
        cursor: pointer;
        user-select: none;
        transition: 0.24s;
    }
    .expandSearch i:hover{
        color: #000000;
    }
    .expandSearch input{
        border:none;
        background: transparent;
        font:14px/1.4 sans-serif;
        padding-left: 26px;
        background:#fff;
        border: 2px solid #ddd;
        border-radius: 4px;
        transition: 0.24s;
        width: 0px;
        padding: 8px 0px 8px 34px;
    }
    .expandSearch input:focus{
        border-color: #aaa;
        outline: none;
        width:200px;
        padding: 8px 12px 8px 34px;
        border-color: #000000;
    }
    .expandSearch input:focus + i{
        /*padding: 8px 4px 8px 8px ;*/
        color: #ddd;
        pointer-events: none;
    }



    @media screen and (min-width: 768px) and (max-width: 1024px){
        div.example {
            max-height: 250px;
            overflow: hidden;
        }
    }
    /* Tablets, iPads (portrait) ---------- */
    @media screen and (min-width: 768px){
        div.example {
            max-height: 250px;
            overflow: hidden;
        }
    }
    /* Tablets, iPads (landscape) ---------- */
    @media screen and (min-width: 1024px){
        div.example {
            max-height: 250px;
            overflow: hidden;
        }
    }
    /* Desktops and laptops ---------- */
    @media screen and (min-width: 1224px){
        div.example {
            max-height: 250px;
            overflow: hidden;
        }
    }
    /* Large screens ---------- */
    @media screen and (min-width: 1824px){
        div.example {
            max-height: 250px;
            overflow: hidden;
        }
    }
}