.bvm{
    body {
        line-height: 1.7;
        color: gray;
        font-weight: 300;
        font-size: 1rem;
        background: #fff;
        font-family: muli, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji
    }

    ::-moz-selection {
        background: #000;
        color: #fff
    }

    ::selection {
        background: #000;
        color: #fff
    }

    a {
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        color: #2f89fc
    }

    a:hover {
        color: #046df4;
        text-decoration: none
    }

    h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
        font-family: muli, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
        line-height: 1.5;
        color: #000
    }

    .border-2 {
        border-width: 2px
    }

    .text-black {
        color: #000 !important
    }

    .bg-black {
        background: #000 !important
    }

    .color-black-opacity-5 {
        color: rgba(0, 0, 0, .5)
    }

    .color-white-opacity-5 {
        color: rgba(255, 255, 255, .5)
    }

    .site-wrap:before {
        -webkit-transition: .3s all ease-in-out;
        -o-transition: .3s all ease-in-out;
        transition: .3s all ease-in-out;
        background: rgba(0, 0, 0, .6);
        content: "";
        position: absolute;
        z-index: 2000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden
    }

    .offcanvas-menu .site-wrap {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        overflow: hidden
    }

    .offcanvas-menu .site-wrap:before {
        opacity: 1;
        visibility: visible
    }

    .btn {
        border-radius: 0
    }

    .btn:hover, .btn:active, .btn:focus {
        outline: none;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    .line-height-1 {
        line-height: 1 !important
    }

    .bg-black {
        background: #000
    }

    .form-control {
        height: 43px;
        border-radius: 0
    }

    .form-control:active, .form-control:focus {
        border-color: #2f89fc
    }

    .form-control:hover, .form-control:active, .form-control:focus {
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    .site-section {
        padding: 2.5em 0
    }

    @media (min-width: 768px) {
        .site-section {
            padding: 5em 0
        }
    }

    .site-section.site-section-sm {
        padding: 4em 0
    }

    .section-heading h2 {
        font-weight: 700;
        color: #000
    }

    .border-top {
        border-top: 1px solid #edf0f5 !important
    }

    .site-footer {
        padding: 4em 0;
        background: #333
    }

    @media (min-width: 768px) {
        .site-footer {
            padding: 5em 0
        }
    }

    .site-footer .border-top {
        border-top: 1px solid rgba(255, 255, 255, .1) !important
    }

    .site-footer p {
        color: #737373
    }

    .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
        color: #fff
    }

    .site-footer a {
        color: #999
    }

    .site-footer a:hover {
        color: #fff
    }

    .site-footer ul li {
        margin-bottom: 10px
    }

    .site-footer .footer-heading {
        font-size: 16px;
        color: #fff
    }

    .bg-text-line {
        display: inline;
        background: #000;
        -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
        box-shadow: 20px 0 0 #000, -20px 0 0 #000
    }

    .text-white-opacity-05 {
        color: rgba(255, 255, 255, .5)
    }

    .text-black-opacity-05 {
        color: rgba(0, 0, 0, .5)
    }

    .hover-bg-enlarge {
        overflow: hidden;
        position: relative
    }

    @media (max-width: 991.98px) {
        .hover-bg-enlarge {
            height: auto !important
        }
    }

    .hover-bg-enlarge > div {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .8s all ease-in-out;
        -o-transition: .8s all ease-in-out;
        transition: .8s all ease-in-out
    }

    .hover-bg-enlarge:hover > div, .hover-bg-enlarge:focus > div, .hover-bg-enlarge:active > div {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2)
    }

    @media (max-width: 991.98px) {
        .hover-bg-enlarge .bg-image-md-height {
            height: 300px !important
        }
    }

    .bg-image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed
    }

    .bg-image.overlay {
        position: relative
    }

    .bg-image.overlay:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        background: rgba(0, 0, 0, .7)
    }

    .bg-image > .container {
        position: relative;
        z-index: 1
    }

    @media (max-width: 991.98px) {
        .img-md-fluid {
            max-width: 100%
        }
    }

    @media (max-width: 991.98px) {
        .display-1, .display-3 {
            font-size: 3rem
        }
    }

    .play-single-big {
        width: 90px;
        height: 90px;
        display: inline-block;
        border: 2px solid #fff;
        color: #fff !important;
        border-radius: 50%;
        position: relative;
        -webkit-transition: .3s all ease-in-out;
        -o-transition: .3s all ease-in-out;
        transition: .3s all ease-in-out
    }

    .play-single-big > span {
        font-size: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-40%, -50%);
        -ms-transform: translate(-40%, -50%);
        transform: translate(-40%, -50%)
    }

    .play-single-big:hover {
        width: 120px;
        height: 120px
    }

    .overlap-to-top {
        margin-top: -150px
    }

    .ul-check {
        margin-bottom: 50px
    }

    .ul-check li {
        position: relative;
        padding-left: 35px;
        margin-bottom: 15px;
        line-height: 1.5
    }

    .ul-check li:before {
        left: 0;
        font-size: 20px;
        top: -.3rem;
        font-family: icomoon;
        content: "\e5ca";
        position: absolute
    }

    .ul-check.white li:before {
        color: #fff
    }

    .ul-check.success li:before {
        color: #8bc34a
    }

    .ul-check.primary li:before {
        color: #2f89fc
    }

    .site-navbar {
        margin-bottom: 0;
        z-index: 1999;
        position: relative;
        width: 100%;
        background: #fff
    }

    .site-navbar .site-logo a {
        font-size: 1.5rem
    }

    .site-navbar .site-navigation.border-bottom {
        border-bottom: 1px solid #f3f3f4 !important
    }

    .site-navbar .site-navigation .site-menu {
        margin-bottom: 0
    }

    .site-navbar .site-navigation .site-menu .active > a {
        color: #2f89fc
    }

    .site-navbar .site-navigation .site-menu a {
        text-decoration: none !important;
        display: inline-block
    }

    .site-navbar .site-navigation .site-menu > li {
        display: inline-block;
        padding: 10px
    }

    .site-navbar .site-navigation .site-menu > li > a {
        padding: 10px 0;
        font-weight: 700;
        color: #000;
        text-decoration: none !important
    }

    .site-navbar .site-navigation .site-menu > li > a:hover {
        color: #2f89fc
    }

    .site-navbar .site-navigation .site-menu .has-children {
        position: relative
    }

    .site-navbar .site-navigation .site-menu .has-children > a {
        position: relative;
        padding-right: 20px
    }

    .site-navbar .site-navigation .site-menu .has-children > a:before {
        position: absolute;
        content: "\e313";
        font-size: 16px;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-family: icomoon
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown {
        visibility: hidden;
        opacity: 0;
        top: 100%;
        position: absolute;
        text-align: left;
        border-top: 2px solid #2f89fc;
        -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .1);
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .1);
        border-left: 1px solid #edf0f5;
        border-right: 1px solid #edf0f5;
        border-bottom: 1px solid #edf0f5;
        padding: 0 0;
        margin-top: 20px;
        margin-left: 0;
        background: #fff;
        -webkit-transition: .2s 0s;
        -o-transition: .2s 0s;
        transition: .2s 0s
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
        position: absolute
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
        bottom: 100%;
        left: 20%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
        border-color: transparent;
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown a {
        text-transform: none;
        letter-spacing: normal;
        -webkit-transition: 0s all;
        -o-transition: 0s all;
        transition: 0s all;
        color: #343a40
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
        color: #2f89fc !important
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
        list-style: none;
        padding: 0;
        margin: 0;
        min-width: 200px
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
        padding: 9px 20px;
        display: block
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
        background: #f4f5f9;
        color: #25262a
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
        content: "\e315";
        right: 20px
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
        left: 100%;
        top: 0
    }

    .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
        background: #f4f5f9;
        color: #25262a
    }

    .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
        color: #2f89fc
    }

    .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
        cursor: pointer
    }

    .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
        margin-top: 0;
        visibility: visible;
        opacity: 1
    }

    .site-mobile-menu {
        width: 300px;
        position: fixed;
        right: 0;
        z-index: 2000;
        padding-top: 20px;
        background: #fff;
        height: calc(100vh);
        -webkit-transform: translateX(110%);
        -ms-transform: translateX(110%);
        transform: translateX(110%);
        -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
        box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
        -webkit-transition: .3s all ease-in-out;
        -o-transition: .3s all ease-in-out;
        transition: .3s all ease-in-out
    }

    .offcanvas-menu .site-mobile-menu {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%)
    }

    .site-mobile-menu .site-mobile-menu-header {
        width: 100%;
        float: left;
        padding-left: 20px;
        padding-right: 20px
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
        float: right;
        margin-top: 8px
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
        font-size: 30px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 0;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
        color: #25262a
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
        float: left;
        margin-top: 10px;
        margin-left: 0
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
        display: inline-block;
        text-transform: uppercase
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
        max-width: 70px
    }

    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
        text-decoration: none
    }

    .site-mobile-menu .site-mobile-menu-body {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;
        padding: 0 20px 20px;
        height: calc(100vh - 52px);
        padding-bottom: 150px
    }

    .site-mobile-menu .site-nav-wrap {
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative
    }

    .site-mobile-menu .site-nav-wrap a {
        padding: 10px 20px;
        display: block;
        position: relative;
        color: #212529
    }

    .site-mobile-menu .site-nav-wrap a:hover {
        color: #2f89fc
    }

    .site-mobile-menu .site-nav-wrap li {
        position: relative;
        display: block
    }

    .site-mobile-menu .site-nav-wrap li.active > a {
        color: #2f89fc
    }

    .site-mobile-menu .site-nav-wrap .arrow-collapse {
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 20;
        width: 36px;
        height: 36px;
        text-align: center;
        cursor: pointer;
        border-radius: 50%
    }

    .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
        background: #f8f9fa
    }

    .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
        font-size: 12px;
        z-index: 20;
        font-family: icomoon;
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-180deg);
        -ms-transform: translate(-50%, -50%) rotate(-180deg);
        transform: translate(-50%, -50%) rotate(-180deg);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease
    }

    .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    .site-mobile-menu .site-nav-wrap > li {
        display: block;
        position: relative;
        float: left;
        width: 100%
    }

    .site-mobile-menu .site-nav-wrap > li > a {
        padding-left: 20px;
        font-size: 20px
    }

    .site-mobile-menu .site-nav-wrap > li > ul {
        padding: 0;
        margin: 0;
        list-style: none
    }

    .site-mobile-menu .site-nav-wrap > li > ul > li {
        display: block
    }

    .site-mobile-menu .site-nav-wrap > li > ul > li > a {
        padding-left: 40px;
        font-size: 16px
    }

    .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
        padding: 0;
        margin: 0
    }

    .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
        display: block
    }

    .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
        font-size: 16px;
        padding-left: 60px
    }

    .site-mobile-menu .site-nav-wrap[data-class=social] {
        float: left;
        width: 100%;
        margin-top: 30px;
        padding-bottom: 5em
    }

    .site-mobile-menu .site-nav-wrap[data-class=social] > li {
        width: auto
    }

    .site-mobile-menu .site-nav-wrap[data-class=social] > li:first-child a {
        padding-left: 15px !important
    }

    .container {
        max-width: 1200px
    }

    .muted, .entry2 p {
        color: #666
    }

    .site-cover {
        background-size: cover;
        background-position: top center;
        position: relative
    }

    .site-cover.overlay:before {
        background: rgba(0, 0, 0, .6);
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }

    .site-cover, .site-cover .same-height {
        padding: 7em 0
    }

    .site-cover .post-entry .post-meta {
        color: #fff;
        font-size: 13px
    }

    .site-cover .post-entry .post-meta .author-figure img {
        width: 30px;
        border-radius: 50%
    }

    .site-cover .post-entry h2 {
        font-family: muli, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
        font-size: 2rem;
        line-height: 1.2;
        color: #fff
    }

    @media (min-width: 992px) {
        .site-cover .post-entry h2 {
            font-size: 3rem
        }
    }

    .site-cover .post-entry h2 a {
        color: #fff
    }

    .site-cover.single-page h1 {
        font-size: 2rem;
        color: #fff
    }

    @media (min-width: 992px) {
        .site-cover.single-page h1 {
            font-size: 5rem
        }
    }

    .site-cover.single-page h1 a {
        color: #fff
    }

    .post-meta {
        font-size: 13px;
        color: #b3b3b3
    }

    .post-sm-entry figure {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 110px;
        flex: 0 0 110px
    }

    .post-sm-entry figure img {
        max-width: 100%;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease
    }

    .post-sm-entry h2 {
        font-size: 1rem;
        line-height: 1.4;
        font-family: muli, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
        color: #000
    }

    .post-sm-entry:hover img {
        opacity: .5
    }

    .post-category {
        display: inline-block;
        padding: 2px 10px;
        border-radius: 4px;
        letter-spacing: .2em;
        font-weight: 700;
        font-size: .6rem;
        text-transform: uppercase
    }

    .bg-image2, .retro-layout .img-1, .retro-layout .img-2 {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat
    }

    @media (max-width: 767.98px) {
        .retro-layout .img-1 {
            height: 400px !important;
            position: relative;
            margin-bottom: 30px
        }
    }

    .retro-layout .mb30 {
        margin-bottom: 30px
    }

    .retro-layout .hentry {
        display: block;
        position: relative;
        border-radius: 4px;
        overflow: hidden
    }

    .retro-layout .gradient {
        position: relative
    }

    .retro-layout .gradient:before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
        background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0)
    }

    .retro-layout .post-category {
        margin-left: 20px;
        margin-top: 10px
    }

    .retro-layout .v-height {
        height: 300px
    }

    .retro-layout .text {
        position: absolute;
        bottom: 0;
        z-index: 10;
        padding: 20px;
        max-width: 350px
    }

    .retro-layout .text h2 {
        color: #fff;
        font-size: 26px
    }

    .retro-layout .text span {
        color: rgba(255, 255, 255, .5)
    }

    .retro-layout .text.text-sm h2 {
        font-size: 18px;
        line-height: 1.5
    }

    .retro-layout .two-col > a {
        width: calc(50% - 15px);
        float: left
    }

    @media (max-width: 767.98px) {
        .retro-layout .two-col > a {
            width: 100% !important;
            float: none !important;
            margin-bottom: 30px
        }
    }

    .retro-layout-2 .v-height {
        height: 200px
    }

    @media (max-width: 991.98px) {
        .retro-layout-2 .img-1 {
            height: 200px
        }
    }

    .retro-layout-2 .h-entry {
        display: block;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center center
    }

    .retro-layout-2 .h-entry .post-category {
        color: #fff
    }

    .retro-layout-2 .h-entry.mb-30 {
        margin-bottom: 30px
    }

    .retro-layout-2 .h-entry .date {
        font-size: 15px
    }

    .retro-layout-2 .h-entry.gradient {
        position: relative
    }

    .retro-layout-2 .h-entry.gradient:before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
        background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0)
    }

    .retro-layout-2 .text {
        position: absolute;
        bottom: 0;
        z-index: 10;
        padding: 20px;
        max-width: 350px
    }

    .retro-layout-2 .text h2 {
        color: #fff;
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 0
    }

    .retro-layout-2 .text span {
        color: rgba(255, 255, 255, .5)
    }

    .retro-layout-2 .text.text-sm h2 {
        font-size: 18px;
        line-height: 1.5
    }

    .retro-layout-2 .gradient {
        position: relative
    }

    .retro-layout-2 .gradient:before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
        background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0)
    }

    .entry2 img {
        margin-bottom: 30px
    }

    .entry2 h2 {
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: 700
    }

    .entry2 h2 a {
        color: #000
    }

    .entry2 .excerpt {
        padding-left: 20px;
        padding-right: 20px
    }

    .entry2 .post-meta {
        color: #b3b3b3;
        font-size: 13px;
        width: 100%;
        display: block
    }

    .entry2 .post-meta a {
        color: #000
    }

    .entry2 .post-meta .author-figure img {
        width: 30px;
        border-radius: 50%
    }

    .entry3, .entry4 {
        margin-bottom: 30px
    }

    .entry3 .text h2, .entry4 .text h2 {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 700
    }

    .entry3 .text h2 a, .entry4 .text h2 a {
        color: #000
    }

    .entry3 .figure, .entry4 .figure {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        opacity: 1
    }

    .entry3:hover figure, .entry3:focus figure, .entry4:hover figure, .entry4:focus figure {
        opacity: .5
    }

    .entry4 {
        margin-bottom: 30px
    }

    .entry4 .text h2 {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700
    }

    .entry4 .text h2 a {
        color: #000
    }

    .entry4 .figure {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px
    }

    .blog-entries .blog-entry {
        display: block;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        margin-bottom: 30px;
        position: relative
    }

    .blog-entries .blog-entry:hover, .blog-entries .blog-entry:focus {
        opacity: .7;
        top: -1px;
        -webkit-box-shadow: 0 3px 50px -2px rgba(0, 0, 0, .2) !important;
        box-shadow: 0 3px 50px -2px rgba(0, 0, 0, .2) !important
    }

    .blog-entries .blog-entry .blog-content-body {
        padding: 20px;
        border: 1px solid #efefef;
        border-top: none
    }

    .blog-entries .blog-entry img {
        max-width: 100%
    }

    .blog-entries .blog-entry h2 {
        font-size: 18px;
        line-height: 1.5
    }

    .blog-entries .blog-entry p {
        font-size: 13px;
        color: gray
    }

    .blog-entries .post-meta {
        margin-bottom: 20px;
        font-size: 14px;
        color: #b3b3b3
    }

    .blog-entries .post-meta .author img {
        width: 30px;
        border-radius: 50%;
        display: inline-block
    }

    .breadcrumb-custom {
        background: 0 0;
        padding: 0
    }

    .breadcrumb-custom li a {
        color: #2f89fc
    }

    .breadcrumb-custom li a:hover {
        color: #fff
    }

    .breadcrumb-custom li.active {
        color: #fff
    }

    .breadcrumb-custom li.breadcrumb-item + .breadcrumb-item:before {
        content: "/";
        color: rgba(255, 255, 255, .3)
    }

    .children-info li {
        display: block;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px dotted #dee2e6
    }

    .sidebar {
        padding-left: 5em
    }

    @media (max-width: 991.98px) {
        .sidebar {
            padding-left: 15px
        }
    }

    .sidebar-box {
        margin-bottom: 4em;
        font-size: 15px;
        width: 100%;
        float: left;
        background: #fff
    }

    .sidebar-box *:last-child {
        margin-bottom: 0
    }

    .sidebar-box .heading {
        font-size: 18px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e6e6
    }

    .tags {
        padding: 0;
        margin: 0;
        font-weight: 400
    }

    .tags li {
        padding: 0;
        margin: 0 4px 4px 0;
        float: left;
        display: inline-block
    }

    .tags li a {
        float: left;
        display: block;
        border-radius: 4px;
        padding: 2px 6px;
        color: gray;
        background: #f2f2f2
    }

    .tags li a:hover {
        color: #fff;
        background: #2f89fc
    }

    .pagination {
        margin-bottom: 5em;
        text-align: center !important;
        display: block
    }

    .pagination li {
        margin-right: 2px;
        margin-bottom: 3px;
        display: inline-block
    }

    .pagination li a {
        border: none;
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        margin: 0;
        display: inline-block;
        text-align: center
    }

    .pagination li a:focus, .pagination li a:active {
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    .pagination li a:hover {
        background: #2f89fc;
        color: #fff;
        border: 1px solid transparent
    }

    .pagination li.active a {
        border: 1px solid transparent !important
    }

    .categories, .sidelink {
        padding: 0;
        margin: 0;
        font-weight: 400
    }

    .categories li, .sidelink li {
        padding: 0;
        margin: 0;
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dotted #dee2e6;
        list-style: none
    }

    .categories li:last-child, .sidelink li:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0
    }

    .categories li a, .sidelink li a {
        display: block;
        font-size: 16px
    }

    .categories li a span, .sidelink li a span {
        position: absolute;
        right: 0;
        top: 0;
        color: #ccc;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease
    }

    .categories li a:hover span, .sidelink li a:hover span {
        color: #000
    }

    .categories li.active a, .sidelink li.active a {
        color: #000;
        font-style: italic
    }

    .comment-form-wrap {
        clear: both
    }

    .comment-list {
        padding: 0;
        margin: 0
    }

    .comment-list .children {
        padding: 50px 0 0 40px;
        margin: 0;
        float: left;
        width: 100%
    }

    .comment-list li {
        padding: 0;
        margin: 0 0 30px;
        float: left;
        width: 100%;
        clear: both;
        list-style: none
    }

    .comment-list li .vcard {
        width: 80px;
        float: left
    }

    .comment-list li .vcard img {
        width: 50px;
        border-radius: 50%
    }

    .comment-list li .comment-body {
        float: right;
        width: calc(100% - 80px)
    }

    .comment-list li .comment-body h3 {
        font-size: 20px;
        color: #000
    }

    .comment-list li .comment-body .meta {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: .1em;
        color: #ccc
    }

    .comment-list li .comment-body .reply {
        padding: 5px 10px;
        background: #e6e6e6;
        color: #000;
        text-transform: uppercase;
        font-size: 14px
    }

    .comment-list li .comment-body .reply:hover {
        color: #000;
        background: #e3e3e3
    }

    .post-entry-horzontal {
        margin-bottom: 30px
    }

    .post-entry-horzontal a {
        overflow: hidden;
        display: table;
        position: relative;
        top: 0;
        -webkit-transition: .1s all ease;
        -o-transition: .1s all ease;
        transition: .1s all ease
    }

    .post-entry-horzontal a .image, .post-entry-horzontal a .text {
        display: table-cell;
        vertical-align: middle
    }

    @media (max-width: 767.98px) {
        .post-entry-horzontal a .image, .post-entry-horzontal a .text {
            display: block
        }
    }

    .post-entry-horzontal a .image {
        width: 200px;
        background-size: cover;
        background-position: center center
    }

    @media (max-width: 767.98px) {
        .post-entry-horzontal a .image {
            width: 100%;
            height: 200px
        }
    }

    .post-entry-horzontal a .text {
        padding: 30px;
        width: calc(100 - 200px);
        border: 1px solid #e6e6e6;
        border-left: none
    }

    @media (max-width: 767.98px) {
        .post-entry-horzontal a .text {
            width: 100%;
            height: 200px;
            border: 1px solid #e6e6e6;
            border-top: none
        }
    }

    .post-entry-horzontal a .text h2 {
        font-size: 20px
    }

    .post-entry-horzontal a .text p {
        color: #999;
        margin-bottom: 30px
    }

    .post-entry-horzontal a:hover {
        top: -3px;
        -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1)
    }

    .search-top .search-top-form {
        position: relative;
        float: right
    }

    .search-top .icon {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 15px;
        color: #fff
    }

    .search-top input {
        color: #fff;
        background: #212121;
        background: #046df4;
        width: inherit;
        min-width: 300px;
        border: none;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 4px 30px 4px 15px;
        font-size: 16px
    }

    .search-top input::-webkit-input-placeholder {
        color: #fff
    }

    .search-top input:-ms-input-placeholder {
        color: #fff
    }

    .search-top input::-ms-input-placeholder {
        color: #fff
    }

    .search-top input::placeholder {
        color: #fff
    }

    @media (max-width: 767.98px) {
        .search-top input {
            width: 150px;
            min-width: 150px
        }
    }

    .search-top input:active, .search-top input:focus {
        background: #0357c2;
        outline: none
    }

    .post-entry-sidebar .post-meta {
        font-size: 14px;
        color: #b3b3b3
    }

    .post-entry-sidebar ul {
        padding: 0;
        margin: 0
    }

    .post-entry-sidebar ul li {
        list-style: none;
        padding: 0 0 20px;
        margin: 0 0 20px
    }

    .post-entry-sidebar ul li a {
        display: table
    }

    .post-entry-sidebar ul li a img {
        width: 90px
    }

    .post-entry-sidebar ul li a img, .post-entry-sidebar ul li a .text {
        display: table-cell;
        vertical-align: middle
    }

    .post-entry-sidebar ul li a .text h4 {
        font-size: 18px
    }

    .search-form-wrap {
        margin-bottom: 5em;
        display: block
    }

    .search-form .form-group {
        position: relative
    }

    .search-form .form-group #s {
        padding-right: 50px;
        background: #f7f7f7;
        padding: 15px;
        border: none
    }

    .search-form .icon {
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .view-all-btn {
        background: #d9d9d9;
        color: #000;
        font-weight: 700;
        font-size: 12px;
        padding: 5px 15px;
        border-radius: 4px
    }

    .view-all-btn:hover {
        background: #bfbfbf;
        color: #000
    }

    .bio img {
        max-width: 150px;
        border-radius: 50%
    }

    .custom-pagination span, .custom-pagination a {
        text-align: center;
        display: inline-block;
        height: 40px;
        width: 40px;
        line-height: 40px;
        border-radius: 50%
    }

    .custom-pagination a {
        background: #2f89fc;
        color: #fff
    }

    .custom-pagination a:hover {
        background: #2f89fc
    }

    .block-13, .slide-one-item {
        position: relative;
        z-index: 1
    }

    .block-13 .owl-nav, .slide-one-item .owl-nav {
        position: relative;
        position: absolute;
        bottom: -90px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .block-13 .owl-nav .owl-prev, .block-13 .owl-nav .owl-next, .slide-one-item .owl-nav .owl-prev, .slide-one-item .owl-nav .owl-next {
        position: relative;
        display: inline-block;
        padding: 20px;
        font-size: 30px;
        color: #000
    }

    .block-13 .owl-nav .owl-prev.disabled, .block-13 .owl-nav .owl-next.disabled, .slide-one-item .owl-nav .owl-prev.disabled, .slide-one-item .owl-nav .owl-next.disabled {
        opacity: .2
    }

    .slide-one-item.home-slider .owl-nav {
        position: absolute !important;
        top: 50% !important;
        bottom: auto !important;
        width: 100%
    }

    .slide-one-item.home-slider .owl-prev {
        left: 10px !important
    }

    .slide-one-item.home-slider .owl-next {
        right: 10px !important
    }

    .slide-one-item.home-slider .owl-prev, .slide-one-item.home-slider .owl-next {
        color: #fff;
        position: absolute !important;
        top: 50%;
        padding: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background: rgba(0, 0, 0, .2);
        -webkit-transition: .3s all ease-in-out;
        -o-transition: .3s all ease-in-out;
        transition: .3s all ease-in-out;
        line-height: 0;
        text-align: center;
        font-size: 25px
    }

    @media (min-width: 768px) {
        .slide-one-item.home-slider .owl-prev, .slide-one-item.home-slider .owl-next {
            font-size: 25px
        }
    }

    .slide-one-item.home-slider .owl-prev > span, .slide-one-item.home-slider .owl-next > span {
        position: absolute;
        line-height: 0;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    .slide-one-item.home-slider .owl-prev:hover, .slide-one-item.home-slider .owl-prev:focus, .slide-one-item.home-slider .owl-next:hover, .slide-one-item.home-slider .owl-next:focus {
        background: #000
    }

    .slide-one-item.home-slider:hover .owl-nav, .slide-one-item.home-slider:focus .owl-nav, .slide-one-item.home-slider:active .owl-nav {
        opacity: 10;
        visibility: visible
    }

    .featured-posts .single-row > div {
        background-size: cover
    }

    .featured-posts .single-row > div:first-child {
        margin-bottom: 10px
    }

    .featured-posts .two-row > div {
        background-size: cover;
        height: calc(45% - 5px)
    }

    .featured-posts .two-row > div:first-child {
        margin-bottom: 10px
    }

    .subscribe-1 h2 {
        font-size: 20px
    }

    .search-form-wrap {
        position: absolute;
        width: 100%;
        background: #fff;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease
    }

    .search-form-wrap.active {
        visibility: visible;
        opacity: 1
    }

    .search-form-wrap .search-btn {
        border: none;
        background: 0 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 20px
    }

    .search-form-wrap .search-btn:active, .search-form-wrap .search-btn:focus {
        outline: none
    }

    .search-form-wrap form .form-control {
        border: none
    }

    .height{
        height: 500px !important;
    }
}