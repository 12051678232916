
.bvm .button, .bvm button, .bvm a.price-send, .bvm input[type="submit"], .bvm input[type="reset"], .bvm input[type="button"], .bvm .button.green, .bvm button.green, .bvm input[type="submit"].green, .bvm input[type="reset"].green, .bvm input[type="button"].green{
  /*display: none !important*/
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointer:hover{
    cursor: pointer;
  color: #fe1361 !important;
}
.pointer-active{
  color: #fe1361 !important;
}
.pointer{
  color: #000000;
}

ui a:hover{
    cursor: pointer;
  color: #fe1361 !important;
}

.responsive {
  position: relative;
  text-align: center;
  color: white;
}
.absolute {
  position: absolute;
  bottom: 25px;
  left: 26px;
}
h3{
  color: black;
}

.fullstack_sec1 {
  padding: 25px 15px;
  box-shadow: 0px 0px 1px 1px #e8ebf1;
  transition: all 0.3s ease-in-out;
  background: #fff;
  margin: 33px !important;
  width: 27% !important;
  max-height: 250px !important;
  float: left;
}

.info-box__shadow:hover {
  box-shadow: 0 20px 60px 0 rgba(0,0,0,.4);
  -webkit-transform: translate(0,-10px);
  transform: translate(0,-10px);
}
.vc_column_container {
  padding-left: 0;
  padding-right: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.testimonial {
  margin-bottom: 20px;
  clear: both;
  min-width: 220px;
  width: 64%;
  padding-bottom: 19px;
  font-size: 18px !important;
  margin: auto !important;
  background: white !important;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px !important;
}
.animation .container {
  animation: fadeInB;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
}

.animation .container1 {
  animation: fadeInA;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
}
.wpb_animate_1{
  animation: fadeInC;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}.wpb_animate_2{
  animation: fadeInD;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
@keyframes fadeInD {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}@keyframes fadeInC {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInB {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInA {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.vc_btn3-container .vc_general  {
   margin-left: 1px;
 }
.wpb_column .wpb_wrapper {
  margin-bottom: 10px;
  margin-left: -10px;
}

.bvm a, .bvm a:visited {
  color: rgb(17 52 129);
  text-decoration: none;
  outline: 0;
}
.testimonials-slider-w.flexslider .flex-direction-nav a i {
  color: #113685 !important;
}
.masterDeveloper{
  color: blue !important;
}

.slick-prev {
    width: 54px;
    height: 54px;
    left:170px;
     z-index: 10;
}

.slick-next{
    width: 54px;
    height: 54px;
    right:170px
}

ul, ol {
  list-style-type: disc;
}
.slick-next:before{
  display: none;
}
.slick-prev:before{
  display: none;
}



@media screen and (max-width: 1200px){
    .carrierHeight{
      height: 730px;
    }
}
@media screen and (max-width: 768px){
    .serviceHeaderFont{
      font-size: 22px;
    }
}
@media screen and (max-width: 768px){
  .c p{
    width: 100% !important;
  }
}
.c p{
  width: 45%;
}

.carrierHeight::-webkit-scrollbar {
  width: 5px;
}
.carrierHeight::-webkit-scrollbar-track {
  background: deeppink;
}
.carrierHeight::-webkit-scrollbar-thumb {
  background-color: blue;
}
.carrierHeight {
  box-shadow:  5px 5px 10px #5d5a5a,
  -5px -5px 10px #ffffff;
}
.carrierHeight:hover {
  box-shadow: inset -5px -5px 10px #5d5a5a,
  inset 5px 5px 10px #ffffff;
}
.slick-slide{
  height: unset!important;
}
  .bvm .wpb_row {
    margin-left: 10px !important;
    margin-right: -10px;
  }
  .bvm .row {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .cardfix .row-wrapper-x div.wpb_column{
      margin-left: 5px !important;
  }

  .BlogTitle{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
  }