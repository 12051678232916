/*company Page*/
.inner-title{
    padding-top: 10%;
    padding-bottom: 11%;
}

.inner-title h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    
}
.custom-h3 h3:after {
    content: '';
    background: #1f3d92;
    height: 3px;
    width: 25%;
    position: absolute;
    left: 15px;
    top: 35px;
}
.lates-talk {
    font-size: 50px;
    color: #fff;
}
p.lates-talk-text {
    font-size: 20px;
    color: #a8c1f5 !important;
}
.advantage-cost-sec {
    margin-bottom: 0 !important;
}
.custom-h3 h3 {
    padding-bottom: 15px;
}
/* php developer */

.icon_with_texts .icon-box2 h4 {
    font-size: 18px !important;
}
.icon_with_texts img {
    margin-bottom: 20px;
    padding: 2px;
}
a.vc_btn3-style-custom {
    color: white !important;
}
.contact_form_sections form.wpcf7-form {
    width: 50%;
    margin: auto;
}
.contact_form_sections form.wpcf7-form input.wpcf7-form-control.wpcf7-submit {
    background-color: #113685 !important;
    width: 90%;
    border-radius: 50px !important;
}
.box_shadows_section .vc_column-inner {
    box-shadow: 1px 3px 4px 1px #ccc;
    padding: 14px;
    margin: 30px;
}
.accordian_sections .vc_active .vc_tta-panel-body {
    background: transparent !important;
    border: none !important;
    padding: 1px 25px !important;
}
.hiring_form form.wpcf7-form input,
.hiring_form form.wpcf7-form textarea {
    box-shadow: none !important;
}
.home .lets-talk {
    display: none;
}
/*Menga menu */
.mega-menu-img img {
    width: 60px;
    margin-top: 15px;
}
/*Footer*/
.footer-navi.floatright a {
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
}
.footer-navi.floatright a:hover {
    color: #2d6cfd !important;
}
.footbot .footer-navi img{
    width: 75%;
}
.footbot{
    padding-top: 20px !important;
}
section.blox.global-footer {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
}
p.lates-talk-text {
    padding-bottom: 50px;
}
@media (min-width: 300px) and (max-width: 767px) 
{
    .custom-h3 h3
    {
        font-size: 16px;
        font-weight: 700;
    }
    .contact_form_sections form.wpcf7-form {
    width: 100% !important;
    margin: auto;
}
    .float_reverce_section {
        display: flex;
        flex-flow: column-reverse;
    }
    .wpb_single_image {
        text-align: center !important;
    }
    .lates-talk {
        font-size: 40px;
        color: #fff;
    }
    .footbot .footer-navi img{
        width: 45%;
    }

}
.ult_modal-body #talk-business input,
.ult_modal-body #talk-business textarea {
    box-shadow: none !important;
    border: 1px solid #dbdcdd !important;
}
.button_alignment {
    text-align: center;
}
.get_started .vc_btn3 {
    padding: 14px 65px !important;
}