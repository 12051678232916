.bvm {

    img._mi._before._image {
        display: none;
    }
    /*----------------------home-page------------------------------------*/
    article.icon-box24 i {
        top: 16px;
    }
    .footer-button a.vc_general{
        padding: 13px 38px !important;
    }
    .button_content button.vc_general.vc_btn3{
        padding: 13px 45px !important;
    }
    .vc_active h4.vc_tta-panel-title.vc_tta-controls-icon-position-right {
        background: #113685 !important;
        color: White !important;
    }
    .vc_active h4.vc_tta-panel-title.vc_tta-controls-icon-position-right a span{
        color: white !important;
    }
    #wrap .easydesign-contact{
        border:none !important;
    }
    section.blox.w-animate.w-start_animation {
        margin-top: -35px;
    }
    .hide1 .wpb_column.vc_column_container.vc_col-sm-12 .vc_column-inner {
        width: 90%;
        margin: auto;
    }
    .owl-item p.tc-content {
        padding: 20px !important;
    }
    .hide1 {
        background: #f6f8f9 !important;
        padding-top: 34px;
    }
    .icon_with_text article.icon-box2 {
        background-color: #f6f8f9 !important;
    }
    #header.sticky .logo-sticky img.img-logo-w3 {
        max-height: 65px !important;
    }
    p.slider-sub {
        font-size: 20px;
        width: 50%;
        line-height: 38px;
        margin: auto;
        padding-top: 14px;
    }
    .hide1 .vc_separator, .work_section .vc_separator {
        margin-bottom: 0px;
    }
    .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner{
        width: 1.3em !important;
    }
    .icon_with_text .icon-box2 img {
        width: 28%;
        padding-bottom: 15px;
    }
    #nav ul li ul{
        width:200px;
    }
    .hireweb{
        border:solid 1px gray;
        margin:5px;
        padding-top:25px;
    }
    .title-php h4{
        color:white;
        font-weight:bolder;
    }
    .transparent-header-w #header.horizontal-w #nav > li > a,
    .transparent-header-w .woo-cart-header .header-cart span,
    .transparent-header-w a.search-form-icon{
        color:white;
    }
    .latestposts-four .latest-b2 h6.latest-b2-cat{
        display:none;
    }
    #nav li.mega ul[class^="sub-"]{
        width: 900px;
        margin-top:-15px;
    }
    #header.sticky #nav li.mega ul[class^="sub-"]{
        left: 285px;
    }
    #nav li.mega ul[class^="sub-"] {
        width: 874px;
        border-radius: 5px;
        border: 4px solid #93d056;
    }
    .home #nav a span, .home #nav > li > a:after {
        margin-top: -1px;
        display: block;
        font-size: 14px;
        float: left;
        font-weight: 800;
    }
    #nav a i{
        vertical-align: middle;
    }
    .whoweare1111 .vc_tta-container{
        margin-bottom:0px;
        margin-left:-70px;
    }
    .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels {
        background-color: #3581c7 !important;
    }
    #nav a{
        padding: 0 10px;
    }
    .call{
        color:black;
    }
    #header.sticky #nav a span,
    #header.sticky #nav a:after{
        display:block;
        color:black;
    }
    #header.sticky #nav-wrap #nav > li > a{
        padding:23px 8px;
    }
    .contec-no a{
        color:green;
    }

    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-lg .vc_tta-tabs-container,
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-lg .vc_tta-tabs-container{
        flex-basis: 30% !important;
    }
    .vc_tta-tabs:not([class*=vc_tta-gap]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right .vc_tta-tabs-container{
        margin-top: 45%;
    }
    .vc_tta-tabs:not([class*=vc_tta-gap]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right .vc_tta-tabs-container{
        margin-top:40%;
    }
    .vc_tta.vc_general .vc_tta-tab>a{
        padding: 30px 20px !important;
    }
    .vc_tta-color-sky.vc_tta-style-flat .vc_tta-tab.vc_active>a {
        background-color: #3581c7 !important;
        color: #fff;
    }
    .icon-box24:hover i {
        border-color: transparent;
        background: #113685;
        color: #fff !important;
    }
    article.icon-box24:hover {
        box-shadow: 1px 3px 16px 1px #ccc;
    }
    #footer.litex .footer-in .widget ul li a{
        color:white;
        font-size:17px;
    }
    #footer{
        padding:35px 0 0 0;
    }
    .row-one.wpb_column.vc_column_container.vc_col-sm-3 .vc_column-inner{
        padding:0px;
    }
    #footer.litex{
        border-top:0px ;
    }
    .esg-overflowtrick{
        width: 100% !important;
        height: auto !important;
    }
    .esg-overflowtrick ul.mainul{
        width: 100% !important;
        height: auto !important;
    }
    .esg-overflowtrick ul.mainul li{
        width: 336px !important;
        height: auto !important;
        position: relative;
        left: 0 !important;
        top: 0 !important;
        float:left;
    }
    .esg-overflowtrick ul.mainul li .esg-media-cover-wrapper .esg-entry-media-wrapper {
        height: 235px;
    }
    .esg-overflowtrick ul.mainul li .esg-media-cover-wrapper .esg-entry-media-wrapper .esg-entry-media {
        height: 235px;
    }
    .vc_tta.vc_general .vc_tta-panel-body{
        padding:0px !important;
    }
    .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab.vc_active>a { background-color: dodgerblue !important; color: #666; }
    .transparent-header-w #header.horizontal-w #nav > li:hover > a,
    .transparent-header-w #header.horizontal-w #nav > li.current > a,
    .transparent-header-w.t-dark-w #header.horizontal-w #nav > li:hover > a,
    .transparent-header-w.t-dark-w #header.horizontal-w #nav > li.current > a {
        color: #113685;
        text-shadow: none;
    }
    .vc_tta-container .Languages li.vc_tta-tab.vc_active a {
        background: #113685 !important;
    }
    .max-title:before,
    .max-title:after,
    .max-title3 h1:before,
    .max-title3 h2:before,
    .max-title3 h3:before,
    .max-title3 h4:before,
    .max-title3 h5:before,
    .max-title3 h6:before,
    .max-title h1:after,
    .max-title h2:after,
    .max-title h3:after,
    .max-title h4:after,
    .max-title h5:after,
    .max-title h6:after{
        content:none;
    }
    article.icon-box24 {
        height: 370px;
    }
    .max-title{
        margin-bottom:0px;
    }
    .icon-box24,
    .icon-box24:after{
        border:0px;
    }
    .icon-box24:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
    }
    .max_background .max-overlay {
        background-color: #0000003b;
    }
    .work_section .icon-box2 {
        box-shadow: 0px 0px 16px hsla(0, 0%, 0%, 0.12);
        height: 330px;
        margin-bottom:40px;
    }
    .button_content .vc_general {
        color: #fff;
        border-color: #113684 !important;
        background-color: #113684 !important;
    }
    .image_text_content img {
        width: 100%;
    }
    .image_text_content {
        box-shadow: 0px 0px 16px rgba(1, 2, 2, 0.11);
    }
    .image_text_content {
        display: flex;
        flex-flow: column-reverse;
    }
    .accordian_section1 {
        width: 80%;
        margin: auto;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-title>a {
        color: white !important;
    }
    #wrap .vc_tta-panel-heading
    {
        border: none !important;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading {
        background-color: #113685 !important;
    }

    .accordian_section1 .vc_tta-panel-body {
        border: none !important;
        background-color: rgba(17, 54, 132, .7) !important;
        color: white !important;
    }
    .accordian_section1 h3, .accordian_section1 p
    {
        color: white !important;
    }
    section.blox.full-container.respo-bg-none.whoweare.w-animate.w-start_animation {
        background-position: 72% 50% !important;
        background-size: inherit !important;
    }
    .accordian_section1 .vc_tta-panel-body .wpb_text_column.wpb_content_element {
        padding: 7px 8px 24px 7px;
    }
    .accordian_section1 .vc_tta-panel-body .wpb_text_column.wpb_content_element h3, .accordian_section1 .vc_tta-panel-body .wpb_text_column.wpb_content_element p
    {
        color: white !important;
    }
    .accordian_section1 .vc_tta-panel-body .wpb_text_column.wpb_content_element h3
    {
        margin-bottom: 18px !important;
    }
    .accordian_section1 .vc_tta-panel {
        margin-bottom: 3px;
        margin-top: 3px;
        margin-left: -1px;
    }
    .are_differen h5 {
        color: white;
        font-weight: 700;
        margin-bottom: 11px;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading {
        background-color: #fff !important;
    }
    h4.vc_tta-panel-title.vc_tta-controls-icon-position-right span.vc_tta-title-text {
        color: #113683;
    }
    .vc_active h4.vc_tta-panel-title.vc_tta-controls-icon-position-right span {
        color: white;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title>a {
        color: #113685 !important;
    }
    .vc_tta .vc_tta-controls-icon.vc_tta-controls-icon-triangle::before
    {
        border-top-color: #103682 !important;
    }
    .vc_active i.vc_tta-controls-icon.vc_tta-controls-icon-triangle:before {
        border-top-color: white !important;
    }
    h4.vc_tta-panel-title.vc_tta-controls-icon-position-right {
        border-left: 5px solid #113684;
    }
    .testimonial_section {
        margin-bottom: 0px !important;
    }
    .vc_sep_width_20.vc_sep_pos_align_center {
        margin-bottom: 0px !important;
    }
    .icon_boxes i.fa {
        margin-right: 8px;
    }
    .footer-button {
        padding-top: 0px !important;
        top: -17px;
        position: relative;
    }
    .footer-button a {
        border-radius: 6px !important;
        box-shadow: 0px 0px 10px hsla(180, 33%, 1%, 0.12);
    }
    .footer-in
    {
        padding-bottom: 7px !important;
    }
    #footer {
        padding: 0px !important;
    }
    #footer.litex .footer-in .widget ul li:hover {
        background: transparent !important;
    }
    #footer.litex .footer-in .widget ul li:hover a
    {
        color: #2d6cfd !important;
    }
    .image_text_content h2 {
        font-size: 24px !important;
        padding: 10px 0px 0px 0px;
    }
    .testimonial-carousel .tc-content
    {
        border: none !important;
        margin-top: 0px !important;
        box-shadow:none !important;
    }
    .testimonial-carousel p.tc-content:after, .testimonial-carousel p.tc-content:before {
        content: none !important;
    }
    .theme-default .nivoSlider{
        box-shadow: 0 0px 16px rgba(1, 2, 2, 0.11) !important;
    }

    .social_icon_text .vc_icon_element {
        width: 25%;
        float: left;
    }
    .social_icon_text h4, .social_icon_text h5 {
        font-size: 35px;
    }
    @media (min-width: 300px) and (max-width: 768px)
    {
        .container, p.slider-sub, .hide1 .wpb_column.vc_column_container.vc_col-sm-12 .vc_column-inner {
            width: 100% !important;
        }
        input.wpcf7-form-control.wpcf7-submit.seo_form_btn_sent.seo_form_btn_style {
            float: none !important;
        }
        span.wpcf7-list-item {
            padding: 9px !important;
            display: -webkit-box;
        }
        .social_icon_text .vc_icon_element {
            width: 100%;
            float: left;
        }
        .checkbox_seo_title1 .wpcf7-list-item:nth-child(3) {
            margin-left: 1em !important;
        }
        .checkbox_seo_title1 .wpcf7-list-item:nth-child(2) {
            margin-left: 1em !important;
        }
        .image_text_content {
            margin-bottom: 20px !important;
        }
        h1 {
            font-size: 28px !important;
        }
        .vc_sep_width_20 {
            width: 60% !important;
        }
        .max-title3 h1, .max-title3 h2, .max-title3 h3, .max-title3 h4, .max-title3 h5, .max-title3 h6
        {
            font-size: 22px !important;
        }
        .accordian_section1 {
            width: 88%;
            margin: auto;
            margin-left: 55px;
        }
        .owl-item {
            height: auto;
            box-shadow: none;
            padding: 0px !important;
            width: 180px !important;
            margin: auto !important;
            transition: none;
            padding-top: 0px !important;
        }
        .footer-button {
            left: -40px !important;
            margin-top: 22px;
        }
        .hide123 article.icon-box2 {
            margin-bottom: 0px !important;
        }
        .owl-item
        {
            height: 470px !important;
        }
        .social_icon_text.social_icon_text1 {
            width: 90% !important;
            height: auto;
        }
        .social_icon_text.social_icon_text1 {
            text-align: center;
        }
        .social_icon_text1 .vc_icon_element-have-style {
            text-align: center !important;
            margin-top: 20px;
        }
        .easydesign-contact .icon_text_content span.vc_icon_element-icon {
            font-size: 1em!important;
        }
        .easydesign-contact .icon_text_content .vc_icon_element-inner.vc_icon_element-color-custom.vc_icon_element-have-style-inner.vc_icon_element-size-md.vc_icon_element-style-rounded-outline.vc_icon_element-outline.vc_icon_element-background-color-custom{
            width: 1.8em!important;
            height: 1.8em!important;
        }
        .easydesign-contact .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner .vc_icon_element-icon:before {
            font-size: 15px;
        }
        span.wpcf7-form-control-wrap.your-email {
            position: relative !important;
            width: 96%;
        }
        input.wpcf7-form-control.wpcf7-submit {
            top: 13px !important;
            right: 0px !important;
            margin-right: 0px !important;
            width: auto !important;
            float: left !important;
        }
        span.wpcf7-list-item {
            padding: 9px !important;
            display: block;
            margin-left: 0px;
        }

    }

    @media (min-width: 769px) and (max-width: 1270px)
    {
        article.icon-box24, .work_section .icon-box2, .owl-item {
            height: auto !important;
        }
        input.wpcf7-form-control.wpcf7-submit
        {
            margin-right: 0px !important;
        }
        .checkbox_seo_title1 .wpcf7-list-item:nth-child(3) {
            margin-left: 1em !important;
        }
    }


    .dashed_border .vc_icon_element-inner {
        border-style: dashed !important;
    }
    .social_icon_text1 {
        border: 1px solid #dbdcdd;
        height: 100%;
        min-height: 100%;
        margin: 43px 16px;
        width: 30% !important;
        padding: 0px 11px !important;
        height: 160px;
    }
    .social_icon_text1 p strong {
        font-size: 20px;
        color: black;
    }
    .social_icon_text1 p, .social_icon_text1 p a{
        color: #616161;
        font-size: 15px;
        margin-bottom: 3px;
    }
    .easydesign-contact .vc_icon_element {
        width: 9%;
        float: left;
        margin: 8px;
    }
    .easydesign-contact .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-size-md .vc_icon_element-icon {
        font-size: 1.4em!important;
    }
    .easydesign-contact .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-size-md.vc_icon_element-have-style-inner {
        width: 2.5em!important;
        height: 2.5em!important;
    }
    .easydesign-contact input,
    .easydesign-contact textarea,
    form.wpcf7-form input,
    textarea.wpcf7-form-control.wpcf7-textarea{
        margin-bottom: 20px !important;
        background: #ecefff !important;
        padding: 15px !important;
        border-radius: 6px !important;
    }
    .easydesign-contact input:active,
    .easydesign-contact textarea:active,
    form.wpcf7-form input:active,
    textarea.wpcf7-form-control.wpcf7-textarea:active,
    .easydesign-contact input:focus,
    .easydesign-contact textarea:focus,
    form.wpcf7-form input:focus,
    textarea.wpcf7-form-control.wpcf7-textarea:focus
    {
        background: transparent !important;

    }
    p.talk-business-title {
        font-size: 14px !important;
        color: black !important;
        margin-bottom: 13px !important;
    }
    .easydesign-contact input.wpcf7-form-control.wpcf7-submit{
        background: #113684 !important;
    }
    .hour-and-month {
        margin-top: 5px;
    }
    .newsletter-form input.wpcf7-form-control.wpcf7-submit {
        background: #113684 !important;
        padding: 11px 20px !important;
    }
    .newsletter-form{
        box-shadow: 1px 3px 16px 1px #ccc;
        padding: 25px;
    }
    .newsletter-form input.wpcf7-validates-as-email {
        box-shadow: 1px 3px 16px 1px #ccc !important;
        padding: 15px 20px !important;
        border-radius: 50px !important;
    }
    span.wpcf7-form-control-wrap.your-email {
        position: absolute;
        width: 86%;
    }
    input.wpcf7-form-control.wpcf7-submit {
        float: right;
        top: 6px;
        right: 4px;
    }
    .newsletter-form form.wpcf7-form {
        padding-bottom: 29px;
    }
    .newsletter-form .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors {
        margin-top: 65px;
    }
    .newsletter-form .wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok {
        margin-top: 48px !important;
    }
    p.seotitle {
        color: black !important;
        font-size: 15px !important;
        margin-bottom: 10px !important;
    }
    .seo_consolation_form input, .seo_consolation_form textarea, .request_form_section input, .request_form_section textarea {
        box-shadow: 1px 3px 16px 1px #ccc !important;
        background: #ecefff !important;
        border-radius: 5px !important;
        padding: 13px 10px !important;
    }
    #seo_consolation_form .wpcf7-list-item-label:before
    {
        background: #ecefff !important;
        width: 20px !important;
        height: 20px !important;
        left: -5px !important;
        margin-right: 20px !important;
    }
    p.checkboxtitle {
        color: black !important;
        font-size: 15px !important;
        margin-bottom: 20px !important;
    }
    input:active, input:focus {
        background: white !important;
        border: 2px solid #113685 !important;
    }
    input.wpcf7-form-control.wpcf7-submit.seo_form_btn_sent.seo_form_btn_style {
        padding: 13px 64px !important;
        background: #113685 !important;
        border-radius: 50px !important;
    }
    span.wpcf7-list-item {
        padding: 20px;
    }
    .checkbox_seo_title1 .wpcf7-list-item:nth-child(3) {
        margin-left: -2px;
    }
    .checkbox_seo_title1 .wpcf7-list-item:nth-child(2) {
        margin-left: 4px;
    }
    #seo_consolation_form .checkbox_seo_title input[type=checkbox]:checked + span.wpcf7-list-item-label:before
    {
        color: black !important;
    }
    input.wpcf7-form-control.wpcf7-submit.seo_form_btn_sent.seo_form_btn_style {
        float: none;
        margin-top: 14px;
    }
    .col-sm-12.seo_contact_btn p
    {
        text-align: center;
    }
    span.wpcf7-list-item-label {
        color: black !important;
        font-size: 14px !important;
        font-weight: normal;
    }
    .box_shadow_section .icon-box24 {
        box-shadow: 0px 0px 16px rgba(1, 2, 2, 0.18);
        height: 260px;
    }
    .button_11 button {
        padding: 15px 64px !important;
        margin-right: 30px;
        font-size: 14px !important;
    }
    button.btn-modal.btn-primary.btn-modal-lg.overlay-show.button_11-button.ult-align-center,
    button.btn-modal.btn-primary.btn-modal-lg.overlay-show.Button_11-button.ult-align-center{
        font-size: 14px !important;
        padding: 15px 30px !important;
    }
    section.container.footer-in {
        display: none  !important;
    }
    .button_11 a.vc_btn3-style-custom {
        font-size: 14px !important;
    }
    .footer-navi.floatright a {
        margin: 0px 10px;
    }
    .footbot {
        background: #22242c !important;
    }
    .footer-navi a:after{
        content: none !important;
    }
    .box_shadow_section .icon-box24 i {
        border-style: dashed !important;
        border: 2px solid;
        padding: 20px;
        width: auto;
        height: auto;
    }
    @media (min-width: 768px) and (max-width: 990px)
    {
        .box_shadow_section article.icon-box24 p {
            font-size: 11px;
        }
        .vc_row .fullstack_sec{
            width: 50% !important;
            margin-bottom: 30px !important;
        }
        .icon_content_sections p
        {
            line-height: 36px;
        }
        .icon_content_sections h5
        {
            font-size: 14px !important;
        }
        section.blox
        {
            background-size: cover !important;
        }

    }
    #nav > li.current > a, #nav > li > a.active, #nav > li:hover > a, li#menu-item-11086 a:hover {
        color: #113685 !important;
    }
    .desc-footer
    {
        text-align: center; color: #8dcfec; font-size: 20px;
    }
    .page-id-9314 section#main-content {
        display: none;
    }
    .page-id-9314 article.icon-box24 {
        height: 300px !important;
    }

    .blog-post a:hover, .blog-author span, .blog-line p a:hover, h6.blog-cat a, h6.blog-date a, a.readmore, .widget ul li.cat-item a:before {
        color: #113685 !important;
    }
    a.readmore:hover {
        background: #113585 !important;
        border-color: #113585 !important;
    }
    a.readmore
    {
        border: 2px solid #113585 !important;
    }
    h6.blog-cat a, h6.blog-date a
    {
        font-weight: 700 !important;
    }
    .widget .subtitle-wrap:before {
        float: right;
        content: '';
        width: 10px !important;
        left: -20px;
        top: 8px;
        transform: rotate(45deg);
        height: 10px !important;
        background-color: #113585 !important;
        display: block;
        margin-bottom: -15px;
        position: relative;
    }
    .widget-tabs .tabs li.active {
        border-top-color: #103584 !important;
    }
    .widget-tabs .tabs li a:hover, .widget-tabs .tabs li.active a, .colorskin-custom a
    {
        color: white !important;
    }
    .sidebar .widget {
        box-shadow: 1px 3px 5px 1px #ccc !important;
        padding: 0px 25px 0px 25px;
    }
    .widget-tabs {
        margin-top: 0px !important;
    }
    .widget-tabs .tabs-container, .widget-tabs .tab_content .tab-list li
    {
        border: none !important;
    }
    .widget-tabs .tabs li {
        border-top: 3px solid #e1e1e1 !important;
    }
    h6.blog-views span, input#submit {
        background-color: #103583 !important;
    }
    .footer-links a {
        color: white !important;
    }
    .menu-item a, .menu-item a span
    {
        color: #103584 !important;
    }


    a.collapsed
    {
        font-weight: 500;
    }
    .card.ea-expand.sp-ea-single {
        border: none !important;
    }
    a.collapsed, .ea-expand h3.ea-header a {
        padding: 11px 21px !important;
        font-size: 20px !important;
        border-left: 5px solid;
    }
    .type-post h2 {
        font-size: 25px;
        font-weight: 600;
    }
    i.ea-expand-icon.fa.fa-plus:before {
        content: "\f106" !important;
        font-size: 25px;
        font-weight: 700;
    }
    i.ea-expand-icon.fa.fa-minus:before
    {
        content: "\f107" !important;
        font-size: 25px;
        font-weight: 700;
    }
    h4.vc_tta-panel-title.vc_tta-controls-icon-position-right {
        background: #ebebeb;
        margin: 15px 0px !important;
        color: black !important;
    }
    .vc_active h4.vc_tta-panel-title.vc_tta-controls-icon-position-right span {
        color: #113683;
    }
    .vc_active i.vc_tta-controls-icon.vc_tta-controls-icon-triangle:before {
        border-top-color: #113683 !important;
    }
    section.wpb_row.w-animate.w-start_animation {
        margin-bottom: 0px;
    }

    h3.banner-subhead {
        font-size: 25px;
        line-height: 32px;
        margin-top: 30px;
    }
    .Request-button a {
        color: white !important;
        padding: 9px 30px !important;
    }
    .Request-button a {
        color: white !important;
        padding: 9px 30px !important;
    }
    .box_shadow_class img,
    .icon_with_texts article.icon-box2
    {
        box-shadow: 1px 3px 5px 1px #ccc !important;
        margin-bottom: 20px;
    }

    .vc_btn3-container.Request-button.request-button1.vc_btn3-inline
    {
        margin-bottom: 0px !important;
    }
    .text-center h4 {
        text-align: center;
    }
    .text-center img {
        width: 20%;
    }
    .text_box_shadow .row-one:hover {
        box-shadow: 10px 1px 16px 1px #ccc !important;
    }
    .text_box_shadow .row-one:hover img {
        width: 98% !important;
        margin: auto !important;
    }
    .text-white p, .text-white h5
    {
        color: white !important;
    }
    #wrap .wpb_gallery {
        border: 0px solid #e5e5e5 !important;
    }
    ul.arrow li:before, li.arrow:before {
        content: "\f105";
        color: #113685 !important;
        font-weight: bold;
    }
    .icon_with_text.icon_with_text2 article.icon-box2,
    .icon_with_text.icon_with_text1 article.icon-box2,
    .icon_with_text.icon_with_text3 article.icon-box2 {
        background-color: transparent !important;
    }
    .home .menu-item a,
    .home .menu-item a span {
        color: white !important;
    }
    .home .sticky .menu-item a,
    .home .sticky .menu-item a span{
        color: #113685 !important;
    }

    .home .menu-item .sub-menu .Mega-menu a
    {
        color: #113685 !important;
    }
    .lates-talk
    {
        text-align: center; color: white; font-size: 70px;
    }
    .desc-footer
    {
        text-align: center; color: #8dcfec; font-size: 20px;
    }
    .cust-heading
    {
        text-align: right; color: white; font-size: 35px;
    }
    .listing
    {
        font-size:16px;
    }
    .phone-1
    {
        display:none !important;
    }
    .tspace .vc_column-inner
    {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .widget img.image {
        left: 7px;
        position: relative;
    }

    a.link_image {
        display: none;
    }
    .flex-caption
    {
        padding-top:2% !important;
        background: transparent !important;
        border: none !important;
    }
    h2.post-title a.link_image {
        display: block !important;
    }
    .flex-caption h6 {
        display: none;
        background: transparent;
    }

    .sub-heading
    {
        font-size: 20px;
    }
    .image_center .vc_column-inner {
        margin: auto;
    }

    span.image_section_width {
        width: 8%;
        float: left;
        margin-bottom: 14px;
    }
    .wpcont5left li {
        padding-bottom: 15px;
    }
    .wpcont5left img {
        width: 50px;
    }
    .custom_button .vc_btn3-style-custom,
    .custom_button1 .vc_btn3-style-custom,
    .custom_button .vc_btn3-style-outline-custom:hover{
        color: white !important;
    }
    .custom_button {
        box-shadow: 0px 0px 13px rgba(1, 1, 1, 0.4);
        margin-right: 25px;
    }
    .testimonial {
        width: 64%;
        padding-bottom: 19px;
        font-size: 18px !important;
        margin: auto !important;
        background: white !important;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
        margin-bottom: 40px !important;
    }
    .testimonials-slider-w .testimonial-content {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
    .testimonials-slider-w.flexslider .flex-direction-nav a i {
        color: #113685 !important;
    }
    .testimonials-slider-w.flexslider .flex-direction-nav a:hover {
        background: #113685 !important;
    }
    .testimonials-slider-w.flexslider .flex-direction-nav a
    {
        border: 2px solid #113685 !important;
    }
    .testimonials-slider-w .testimonial-content h4 q
    {
        font-size: 18px !important;
    }
    .testimonial .testimonial-brand img,
    .box_shadow img.vc_single_image-img.attachment-full {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.4) !important;
    }
    ul.font_size_section img {
        width: 33px;
    }
    ul.font_size_section li figure
    {
        display: -webkit-box;
        margin-bottom: 13px !important;
    }
    ul.font_size_section li p{
        font-size: 16px;
        line-height: 25px;
        padding-left: 3px;
        padding-top: 4px;
    }
    .content_images .col-md-2.col-xs-2,
    .content_images .col-md-10.col-xs-10
    {
        padding: 0px !important;
    }
    .box_shadow_content .vc_column-inner {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, .3);
        padding: 55px -30px 0px 0px !important;
        margin-right: 25px !important;
        height: 100%;
        max-height: 500px;
        min-height: 580px !important;
    }
    .box_shadow_content ul
    {
        margin: 0px !important
    }
    .box_shadow_content {
        margin: 25px !important;
        width: 45% !important;
    }
    div#wpcf7-f6252-p9897-o1 span.wpcf7-form-control-wrap.your-email
    {
        position: relative !important;
        width: 95% !important;
    }
    div#wpcf7-f6252-p9897-o1 input.wpcf7-form-control.wpcf7-submit {
        float: left;
        background: #113685 !important;
    }
    div#wpcf7-f6252-p9897-o1 {
        width: 70%;
        margin: auto;
    }
    .margin_sections1
    {
        text-align: center !important;
    }
    .testimonial_width .owl-item {
        height: 350px !important;
        box-shadow: 1px 3px 16px 1px #ccc;
        width: 14% !important;
        margin: 39px !important;
    }
    .testimonial-carousel .tc-navigation .btn.next:hover,
    .testimonial-carousel .tc-navigation .btn.prev:hover {
        background: #113685 !important;
        color: #fff !important;
        border-color: #113685 !important;
    }

    /*contact section css on home page start*/
    .footer-button a:hover{
        color:#fff !important;
    }
    .image_spacing_section img.aligncenter {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 13%;
    }

    .vc_tta-tabs .vc_tta-tabs-container li.vc_tta-tab.vc_active a {
        color: white !important;
        font-weight: 800;
    }

    /*----------------------------web designer---------------------*/

    ul.arrow li {
        display: flex !important;
    }
    ul.arrow li strong {
        color: #113685;
    }
    .icon_section article.icon-box6 {
        height: 220px;
    }
    .box_shadows:hover {
        box-shadow: -9px 6px 9px 2px #cccccc96;
        margin: 8px;
        margin-bottom: 40px !important;
    }
    .box_shadows {
        margin: 8px;
        margin-bottom: 40px !important;
    }
    /* php developer */
    .title-php h4 {
        color: #616161 !important;
        font-weight: bolder;
    }
    .background_color {
        background: #113685;
    }

    div#talk-business .main_tilte_section {
        background: #113685 !important;
        color: white;
        font-size: 18px;
        padding: 20px;
        text-align: center;
    }
    .hiring_form input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin: auto;
        margin-bottom: 11px !important;
    }
    .hiring_form div#talk-business textarea {
        box-shadow: none !important;
    }
    #talk-business input:active,
    #talk-business input:focus,
    div#talk-business textarea:active,
    div#talk-business textarea:focus {
        background: white !important;
        border: 2px solid #113685 !important;
    }
    #talk-business input{
        border-radius: 2px;
        border: none;
        box-shadow: none;
        background-color: transparent !important;
        margin-bottom: 16px;
        border: 1px solid #dbdcdd !important;
        padding: 14px 14px;
    }
    #talk-business textarea{
        background-color: transparent !important;
        border: 1px solid #dbdcdd !important;
        margin-right: 0px !important;
        width: 99.5% !important;
    }
    div#talk-business .col-sm-2.col-sm-offset-5 input.wpcf7-form-control.wpcf7-submit.cust-btn {
        background: #113685 !important;
        padding: 15px 60px !important;
        font-size: 15px;
    }
    .mo {
        width: 99%;
    }

    .wpcf7-list-item label {
        display: grid;
    }
    #nav li.mega ul[class^="sub-"] {
        width: 874px;
        border:1px solid #d2d2d2 !important;
        border-radius: 5px;
    }

    /* Blog page */

    h3.rec-title {
        color: #113685;
    }
    .sidebar .widget .testimonial {
        box-shadow: none !important;
        width: 100% !important;
        margin-bottom: 60px !important;
    }
    .rec-posts article.rec-post {
        min-height: 330px !important;
    }
    .contact_form_section form.wpcf7-form input.wpcf7-form-control.wpcf7-submit {
        background: #113685 !important;
        border-radius: 50px !important;
        box-shadow: none !important;
        padding: 18px 60px !important;
    }
    div#talk-business .row.contact_information_btns input.wpcf7-form-control.wpcf7-submit {
        background-color: #113685 !important;
        padding: 19px 67px !important;
        margin-bottom: 50px !important;
    }
    ul.col-left.arrow_content li {
        display: block !important;
    }
    .icon_sections_box_shadow article.icon-box6 {
        box-shadow: 1px 3px 6px 1px #ccc;
    }
    ul.plus li:before, li.plus:before
    {
        color: #113685 !important;
    }
    .social_icon_text .wpb_column.vc_column_container.vc_col-sm-12 .vc_icon_element .vc_icon_element-inner {
        width: 4.5em !important;
        height: 4.5em !important;
    }
    .subscribe_form form.wpcf7-form input.wpcf7-form-control.wpcf7-submit {
        padding: 10px 25px !important;
    }
    .Buttone_aligment .wpb_wrapper {
        text-align: center !important;
    }
    @media (max-width: 1280px){
        .content_width_section {
            width: 100%;
            text-align: center;
            margin-bottom: 35px;
        }
        .digit {
            font-size: 30px !important;
        }
        .vc_btn3-container.footer-button {
            margin-bottom: 0;
        }
        article.icon-box24, .work_section .icon-box2, .owl-item {
            height: auto !important;
        }
        .custom-h3 h3 {
            padding-bottom: 15px;
            font-weight: 700;
            font-size: 20px;
        }
        .inner-title h2
        {
            font-size: 38px !important;
        }
    }
    @media (max-width: 280px){
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            height:120% !important;
        }
    }
    @media (min-width: 300px) and (max-width: 400px){
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            height:110% !important;
        }
    }
    @media (min-width: 401px) and (max-width: 500px){
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            height:90% !important;
        }
    }
    @media (min-width: 501px) and (max-width: 768px){
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            height:70% !important;
        }
    }
    @media (min-width: 769px) and (max-width: 1000px){
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            height:110% !important;
        }
    }
    @media (max-width: 768px){
        .image_css.wpb_column.vc_column_container.vc_col-sm-4 {
            width: 100% !important;
        }
        .contact_form_section .map_section {
            padding-right: 0px !important;
        }
        .digit {
            font-size: 30px !important;
        }
        .icon-box24 h4{
            line-height: 30px !important;
        }
        p.slider-sub {
            line-height: 25px;
            font-size: 18px;
        }
        .max-title.max-title3 h2 {
            line-height: 30px;
        }
        .image_text_content h2 {
            font-size: 18px !important;
        }
        .icon-box2 h4 br {
            display: none;
        }
        .text_heding_section h4 {
            line-height: 35px !important;
            font-size: 23px !important;
        }
        p {
            font-size: 15px !important;
        }
        .inner-title h2 {
            padding-bottom: 30px;
            font-size: 25px !important;
        }
        .always_find_time h3 {
            font-size: 18px;
            padding-bottom: 25px;
            line-height: 23px;
        }
        .icon_space_section p span {
            font-size: 40px !important;
            line-height: 0;
        }
        .icon_space_section h5 {
            padding-bottom: 22px;
        }
        .icon_space_section .vc_icon_element-align-center
        {
            margin-bottom: 40px !important;
        }
        .icon_space_section1 .vc_icon_element-align-center {
            margin-bottom: 10px;
        }
        .icon_space_section1 h6 {
            padding-bottom: 20px;
        }
        .responsive_dec .website_dec {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .dedicated_title h2 {
            font-size: 33px !important;
        }
        ul.arrow2 ul {
            margin-left: 0px;
        }
        .text-center img{
            margin-bottom: 25px !important;
            width: 11% !important;
        }
        .vc_btn3-container.Request-button.request-button1.vc_btn3-inline {
            margin-bottom: 25px !important;
        }
        h2.headeing-sub {
            font-size: 20px !important;
            line-height: 30px;
        }
        .flex_section {
            display: flex !important;
            flex-flow: column-reverse !important;
        }
        .wpb_single_image img.vc_single_image-img {
            margin-bottom: 29px;
        }
        span.image_section_width {
            margin-bottom: 4px !important;
        }
        h3.part1 {
            text-align: center !important;
            margin-bottom: 12px !important;
            margin-top: 25px;
        }
        ul.arrow ul {
            margin-left: 0 !important;
        }
        h2.cust-main-t1 {
            font-size: 20px;
        }
        .vertical-space2 {
            height: 40px;
        }
        .box_shadows_section .vc_column-inner.vc_custom_1567229241221 {
            margin-left: 0 !important;
        }
        h2.cust-1 {
            font-size: 22px !important;
            line-height: 30px !important;
        }
        h5.cust-1-sub {
            font-size: 14px !important;
            padding-bottom: 25px;
        }
        h2.cust-h4-new {
            font-size: 28px;
            line-height: 35px;
        }
        .testimonial_width .owl-item {
            height: auto !important;
            box-shadow: none !important;
            width: 17% !important;
            margin: 5px !important;
        }
        .testimonial-carousel .tc-content {
            padding: 20px 0px !important;
            font-size: 12px !important;
        }
        .Wpsite-box .col-md-2 {
            text-align: center;
            margin-bottom: 25px;
        }
        .testimonials-slider-w .testimonial-content {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .testimonials-slider-w .testimonial-content h4 q {
            font-size: 14px !important;
        }
        .testimonials-slider-w.flexslider .flex-direction-nav a {
            bottom: 29px !important;
        }
        .testimonial {
            width: 100%;
            padding-bottom: 19px;
            font-size: 18px !important;
            margin: auto !important;
            background: white !important;
            box-shadow: none;
            margin-bottom: 82px !important;
        }
        h3
        {
            font-size: 20px !important;
        }
        .text_centers .wpb_wrapper {
            text-align: center !important;
            margin-bottom: 40px !important;
        }
        .box_shadow_content {
            margin: 25px !important;
            width: 93% !important;
            //height: 110% ;
        }
        .box_shadow_content .vc_column-inner {
            max-height: min-content !important;
            min-height: 580px !important;
        }
        .margin_top .wpb_text_column {
            margin-top: 0px !important;
        }
        .padding_bottom .vc_column-inner {
            padding-bottom: 0px !important;
        }
        .margin_top .wpb_text_column h2 {
            font-size: 25px !important;
        }
        .col-sm-2.col-md-2 {
            text-align: center;
            padding-bottom: 25px !important;
        }
        .vc_row .fullstack_sec{
            margin-bottom: 30px !important;
            width: 100% !important;
        }
    }
    @media (min-width: 768px) and (max-width: 960px)
    {
        .image_css.wpb_column.vc_column_container.vc_col-sm-4 {
            width: 100% !important;
        }
        .testimonial_width .owl-item {
            height: 400px !important;
            width: 13% !important;
        }
        .testimonials-slider-w.flexslider .flex-direction-nav a{
            bottom: 0px !important;
        }
        .social_icon_text .wpb_column.vc_column_container.vc_col-sm-12 .vc_icon_element .vc_icon_element-inner {
            width: 3.5em !important;
            height: 3.5em !important;
        }
        .vertical-space2 {
            height: 40px !important;
        }
        .digit {
            font-size: 30px !important;
        }
        .social_icon_text .wpb_column.vc_column_container.vc_col-sm-12 .vc_icon_element .vc_icon_element-inner span.vc_icon_element-icon {
            font-size: 30px !important;
        }
        .Project_section {
            width: 33.33% !important;
        }
        .wpb_wrapper .icon_with_texts .icon-box2 h4 {
            font-size: 11px !important;
        }
    }
    @media (min-width: 900px) and (max-width: 1024px)
    {
        .wpb_wrapper .icon_with_texts .icon-box2 h4 {
            font-size: 13px !important;
        }
        .Project_section {
            width: 25% !important;
        }
        .testimonial_width .owl-item {
            height: 420px !important;
            width: 20% !important;
            margin: 9px !important;
        }
        .testimonial-carousel .tc-item {
            padding: 10px 9px !important;
        }
        .testimonial-carousel .tc-content{
            padding:0px !important;
        }
    }


    .post_section {
        position: absolute;
        margin-top: 15%;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        left: 0px;
        color: white !important;
    }
    .post_image_section {
        width: 100%;
        height: 300px;
        margin-bottom: 47px;
    }
    .zoom--img {
        margin-top: 25px;
    }
    a.zoom-in,
    a.zoom-out {
        margin: 5px;
        padding: 2px 13px;
        background: #214899;
        color: white !important;
        font-size: 23px;
        margin-bottom: 25px !important;
    }
    .hover_section:hover .overlay {
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 300px;
    }
    .post_titles {
        color: #fff !important;
        font-size: 28px;
        padding: 25px 10px 10px;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: 600;
    }
    .post_section
    {
        visibility: hidden;
    }
    .hover_section:hover .post_section
    {
        visibility: visible !important;
    }
    .category_section
    {
        color: white !important;
    }
    .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
    }

    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 0px;
        border: 1px solid #888;
        width: 80%;
    }

    /* The Close Button */
    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    .row.mt-10 {
        margin-top: 30px;
    }
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    .modal-content .col-sm-8.col-md-8 {
        padding-top: 20px !important;
        height: 600px;
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 20px !important;
    }
    p.tags_sections a {
        text-transform: capitalize;
        color: black !important;
        font-weight: 600;
        background: white;
        padding: 4px 14px;
        margin: 3px;
    }
    p.tags_sections
    {
        color: black !important;
        font-weight: 600;
    }



    ul.tabs-nav li
    {
        padding: 10px !important;
    }
    ul.tabs-nav
    {
        display: flex !important;
        list-style: none !important;
    }
    ul.tabs-nav li {
        padding: 10px 30px !important;
        background: #103685;
        color: white !important;
        border: 2px solid #113685;
    }
    ul.tabs-nav li a{
        color: white !important;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
    }
    li.tab-active {
        background: #fff !important;
        border: 2px solid #113685 !important;
        border-bottom: none !important;
    }
    ul.tabs-nav.tab-active li a{
        color: #000 !important;
    }
    ul.tabs-nav li.tab_section.tab-active a {
        color: black !important;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
    }

    div#myModal {
        background: rgba(0, 0, 0, 0.8);
    }

    .col-sm-8.col-md-8::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    .category_section ul li:before {
        content: "\2022";
        color: red;
        top: 2px;
        font-size: 20px;
        position: relative;
    }
    .category_section ul
    {
        list-style: none;
        display: inline-flex;
    }
    .category_section ul li {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
    }
    .modal-content .col-sm-4.col-md-4 {
        padding-top: 20px;
        height: 600px;
        padding-bottom: 20px;
        background: #efefef;
    }
    h4.cust-6.title-txt {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }
    h2.cust-5 strong {
        font-weight: 600;
    }
    span.col-md-4.col-xs-3.digit {
        font-size: 40px !important;
    }
    ul.arrow li.list_after:before {
        content: none;
    }
    .border_sections.wpb_column.vc_column_container.vc_col-sm-6 {
        margin-bottom: 5px !important;
        margin-left: 10px !important;
        border-right-width: 2px !important;
        border-bottom-width: 2px !important;
        border-right-color: #c1c1c1 !important;
        border-right-style: solid !important;
        border-bottom-color: #c1c1c1 !important;
        border-bottom-style: solid !important;
        border-radius: 5px !important;
        margin-top: 51px;
    }

    /*.phone-1
    {
        display:none !important;
    }
    li#menu-item-11086 {
        margin-left: -24px ;
    }
    li#menu-item-11086 a:hover {
        color: #444 !important;
    }
    li#menu-item-12071 a:hover {
        color: #444 !important;
    }*/
    .box1
    {
        box-shadow:5px 5px 2px #c1c1c1;
    }
    #main-content.container
    {
        padding-top:0px;
    }
    .ult-adjust-bottom-margin
    {
        margin-bottom: 0px !important;
    }
    .modal11
    {
        padding:12px 20px;
        margin-right:10px;
    }
    /*#nav a span, #nav > li > a:after
    {
        color:#444;
        font-weight:600;
    }*/
    h5.jobs_title {
        font-size: 16px;
        font-weight: 600;
    }
    .jobs_vacs{
        display: none;
    }
    .col-sm-2.col-md-2 img {
        padding: 0px;
        width: auto !important;
        left: 14px;
        position: relative;
        top: 8px;
    }
    p.jobs_vac
    {
        text-align: right;
    }
    .jobs_vacs {
        margin-top: 25px;
        margin-left: 15px;
    }
    li.list_contents:before {
        content: none !important;
    }
    li.list_contents ul {
        margin-left: 12px;
    }
    article.icon-box24 a.magicmore {
        color: black !important;
        font-weight: 500 !important;
    }
    div#talk-business {
        margin-top: 30px;
    }
    /* react js */

    .intricate h2 {
        font-size: 30px !important;
        font-weight: 300;
        line-height: 40px;
    }
    .react_js_content img {
        width: 75% !important;
    }
    .titles {
        font-weight: 700;
        font-size: 18px;
        line-height: 50px !important;
    }
    .titles_sec{
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px !important
    }
    .desc {
        margin-bottom: 20px !important;
    }
    .developer_sec .col-sm-2.col-md-2 img {
        width: auto;
        left: 14px;
    }
    .fullstack_sec {
        margin-bottom: 60px;
        width: 23% !important;
        background: #fff;
        padding: 30px 0px;
        margin-right: 2%;
        height: 100%;
        max-height: 230px !important;
        border-top: 5px solid #113685;
    }
    .fullstack_sec1 {
        padding: 25px 15px;
        box-shadow: 0px 0px 1px 1px #e8ebf1;
        transition: all 0.3s ease-in-out;
        background: #fff;
        margin: 33px !important;
        width: 27% !important;
        max-height: 250px !important;
    }
    .fullstack_sec1:hover {
        margin-top: -20px !important;
        padding-top: 45px;
        box-shadow: 1px 0px 34px 1px rgba(0, 0, 0, 0.13), 0px 15px 34px 1px rgba(0, 0, 0, 0.13);
    }
    .display_nones {
        display: none;
    }
    .conetent_middle .vc_column-inner {
        vertical-align: middle;
        margin: auto;
    }
    .hire_angular_development img {
        width: 15%;
    }
    .hire_developer_shadowbox .vc_column-inner .wpb_text_column.wpb_content_element {
        box-shadow: 0px 1px 15px rgba(0,0,0,.1);
        padding: 30px;
        min-height: 275px;
        transition: .5s;
    }
    .vertical_middle .vc_column-inner {
        margin: auto;
    }
    .hire_developer_shadowbox.developer_status .wpb_text_column.wpb_content_element {
        min-height: 350px;
    }
    .center_class .aligncenter {
        width: 18%;
    }
    .background_section {
        background-size: 70% !important;
        background-position: -20% 0% !important;
    }
    .image_css {
        background: white;
        margin: 20px;
        width: 30% !important;
        padding: 20px !important;
    }
    .image_css img.size-full.aligncenter {
        width: 15%;
    }
    .popup_open {
        cursor: pointer !important;
    }
    .privercy_content h2 {
        font-weight: 600;
        font-size: 38px;
        margin-bottom: 25px;
    }
    section#headline {
        border: none;
    }
    .term_content h3 {
        font-weight: 600;
        font-size: 20px;
        color: #103685;
    }
    .term_content h5 {
        font-weight: 600;
    }
}